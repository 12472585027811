.job-description {
    color: gray;
}

#job-title {
    color: black;
    padding-top: 65px;
}

.left-side {
    text-align: left !important;
    float: left;
}

.subtitle {
    color: gray;
}

.card-background {
    background-color: rgb(232, 232, 232);
}

.card-title {
    padding-top: 25px;
}

#font-size {
    font-size: 16px;
}

#experience-title {
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid black;
    line-height: 0.1em;
    margin: 10px 0 20px;
    font-size: 24px; 
}

#experience-title span {
    background-color: #fbfbfd;
    padding: 0 100px; 
    color: Black;
}

.padding-test {
    padding-left: 45px;
}

.experience-text {
    line-height: 1.1;
    font-size: 18px;
}

@media screen and (max-width: 650px) {
    p {
      font-size: 16px;  
    }
    #row-flex {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    #mobile-space {
        padding-top: 25px;
    }

    #yoovo-container{
        flex-direction: column;
    }

    #software-developer-container {
        flex-direction: column;
    }

    #experience-title {
        font-size: 18px;
    }

    #experience-title span {
        padding: 0 40px; 
    }

    .left-side {
        padding-top: 15px;
    }

    #testing {
        font-size: 20px;
        margin: 10px 0 10px;
    }

    #testing span {
        padding: 0 50px; 
    }

    .mobile-break {
        display: none;
    }

    .container-padding {
        padding-top: 25px;
    }
}

#wallpaper-thumbnail {
    max-width: 205px;
    padding-left: 10px;
    padding-right: 10px;
}

@media screen and (max-width: 480px) {
    #wallpaper-thumbnail {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
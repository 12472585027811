.new-row { 
    display: flex;
    justify-content: center;
    width: 100%;;
}

.first-row {
    display: flex;
    justify-content: center;
}

.second-row {
    display: flex;
    justify-content: center;
}

.card-image {
    position: relative;
    width: 50vh;
}

.rec--image {
    max-width: 50vh;
    display: block;
}

.card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    width:  100%;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
}

.card-title {
    font-size: 20px;
}

.card-overlay p {
    font-size: 16px;
    font-weight: 500;
    color: rgb(160, 160, 160);
}

.card-overlay:hover {
    opacity: 1;
    transition: 0.7s;
}

.test {
    color:rgb(100, 100, 100);
}

#Headertesting {
    font-size: 24px;
}

#testing {
    font-size: 18px;
}

.break-mobile {
    display: none;
}


    /***************
        MOBILES
    ***************/
     @media screen and (max-width: 650px) {
        .bodyText {
        padding-top: 10px;
        }

        .card-overlay {
            max-width: 90%;
        }
        #aboutMe {
            position: relative;
            margin-top: 240px;
            position: absolute;
        }

        #photoDump {
            position: relative;
            width: 100%;
        }

        .first-row, .second-row {
            display: block;
        }

        .card-image, .rec--image, .card-link {
            flex-direction: column;
            display: block;
            max-width: 100%;
        }

        .card-title {
            font-size: 16px;
        }

        .card-overlay p {
            font-size: 12px;
        }

        .break-mobile {
            display: contents;
        }
    }

    /***************
        TABLETS
    ***************/
    /* @media screen and (max-width: 912px) {
        .bodyText {
            padding: 15%;
            text-align: center !important;
        }

        #photoDump {
            margin: auto;
            right: 50%;
        }
    } */

    /***************
        HUBS
    ***************/
    /* @media screen and (max-width: 1280px) {
        .bodyText {
            padding: 15%;
            text-align: center !important;
        }
    
        #photoDump {
            margin: auto;
            width: 100%;
        }
    
    } */

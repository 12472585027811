/* @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.App {
    background-color: #fbfbfd !important;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

body {
    background-color: #fff !important;
    font-family: 'Poppins', sans-serif;
    background-size: cover;
    text-align: center;
}

#logo {
    max-width: 35vh;
}

/***************
    NAVBAR
***************/

.navBar-li:hover, .icon-link:hover {
    color: #505050 !important;
}

#navBar-toggle {
    color: gray !important;
    border: 1px solid #fff !important;
    background-color: #fff;
}


/***************
    ABOUT ME
***************/
#page-logo {
    max-width: 25vh;
}

p {
    font-size: 20px;
    font-family: 'Rubik', sans-serif;
}

h1, h4 {
    font-family: 'Rubik', sans-serif;
}

.aboutMeheader {
    margin-top: 100px;
}

/* .aboutMeImage {
    height: 100vh;
    float: left;
    margin-right: 15px;
    margin-top: 25px;
    border-radius: 16px;
} */

#aboutMeImage {
    max-height: 800px;
    float: left;
    margin: 5%;
    border-radius: 16px;
}

.description {
    padding-top: 30px;
}

.texting {
    opacity: 0;
}

.texting:hover, .hover-test:hover {
    opacity: 1;
}


@media screen and (max-width: 480px) {
    #test-image, #test-image2, #test-image3, #test-image4, #test-image5, #test-image6 {
        max-width: 92%;
        padding-bottom: 15px;
    }

    #page-logo {
        max-width:15vh;
    }
}



    /***************
      ABOUT ME/MOBILES
    ***************/
    @media screen and (max-width: 480px) {
        .myName {
            margin: 0%;
        }
        #aboutMeImage {
            margin: auto;
            width: 100%;
            padding-bottom: 7%;
        }

    }

    /***************
      ABOUT ME/TABLETS
    ***************/
    @media screen and (max-width: 912px) {
        .myName {
            margin: 0%;
        }
        
        .description {
            display: inline;
        }

        #aboutMeImage {
            margin: auto;
            width: 100%;
        }
    }

    /***************
      ABOUT ME/HUBS
    ***************/
    @media screen and (max-width: 1280px) {
        .myName {
            margin: 0%;
        }
    }

/***************
    CONTACT
***************/
.firstNameBox, .lastNameBox, .infoBox {
    border-radius: 16px;
    width: 100%;
    padding: 9px; 
    display: block;
    border: 1px solid;
}

.firstNameBox, .lastNameBox, .infoBox {
    background-color: rgb(250, 250, 250);
}

.contactBtn {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-color: #1266F1 !important;
    color: white !important;
    background-color: #1266F1;
    border: solid;
    border-radius: 8px;
    text-align: center;
}

.divider {
    width: 10px;
    height:auto;
    display:inline-block;
}

#test {
    padding: 0;
    padding-right: 85px;
    width: 75%;
}

#test-2 {
    padding: 0;
}

#contact-divider {
    padding: 10px;
}

@media screen and (max-width: 480px) {
    #test {
        padding-right: 10px;
    }
    #test-2 {
        padding-left: 10px;
    }
}